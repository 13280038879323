<template>
	<div>
		<el-row>
			<div style="display: inline-block; width: 100%; height: 100%">
				<div class="child-top" style="padding-top: 0">
					<div class="input-from">
						<el-input
							class="left-child-input left-child-input-content"
							clearable
							placeholder="请输入模板名称"
							style="width: 200px; float: left"
							suffix-icon="iconfont iconsousuo"
							v-model="keyword"
						></el-input>
						<el-button class="searchBtn" round @click="search">搜索</el-button>
					</div>

					<div class="operation-button">
						<el-button class="new_btn" operation-button round @click="postAdd()" :disabled="$store.getters.permissionsStr('新增')">
							<span>新增</span>
						</el-button>
						<el-button class="del_btn" round @click="someDels()" :disabled="$store.getters.permissionsStr('删除') || !selectCheckData.length">
							<span>删除</span>
						</el-button>
					</div>
				</div>

				<div class="content-flex-pages" style="background: #fff">
					<!-- :data="!$store.getters.permissionsStr('列表') ? tableData : []" -->

					<el-table
						:empty-text="tableData.length ? '' : '暂无数据'"
						:data="!$store.getters.permissionsStr('列表') ? tableData : []"
						class="content-test-table"
						style="width: 100%"
						v-loading="pictLoading"
						@selection-change="selsChange"
					>
						<el-table-column type="selection" width="45"></el-table-column>
						<el-table-column label="序号" type="index" width="45">
							<template slot-scope="scope">{{ (page - 1) * page_size + scope.$index + 1 }}</template>
						</el-table-column>
						<el-table-column label="训练模板名称" prop="name" show-overflow-tooltip></el-table-column>
						<el-table-column label="包含动作数" prop="action_quantity" show-overflow-tooltip></el-table-column>
						<el-table-column label="创建人" prop="create_by" show-overflow-tooltip></el-table-column>
						<el-table-column label="创建时间" prop="create_time" show-overflow-tooltip></el-table-column>
						<el-table-column label="状态" prop="status" show-overflow-tooltip width="80">
							<template slot-scope="scope">
								<el-switch
									v-show="scope.row.uuid"
									active-color="#0055E9"
									:active-value="1"
									:inactive-value="0"
									:width="35"
									@change="ChangeState(scope.row)"
									inactive-color="#ccc"
									v-model="scope.row.status"
									:disabled="$store.getters.permissionsStr('启/停用')"
								></el-switch>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="200">
							<template slot-scope="scope">
								<el-button class="right20 blueColor" size="medium" type="text" @click="postEdit(scope.row)" v-show="scope.row.uuid" :disabled="$store.getters.permissionsStr('编辑')"
									>编辑</el-button
								>
								<!-- v-show="!$store.getters.permissionsStr('编辑')" -->
								<!-- :disabled="scope.row.edit_flag==1?false:true" -->
								<el-button
									class="right20"
									size="medium"
									type="text"
									style="color: #1a9f16"
									@click="getIndicator(scope.row)"
									v-show="scope.row.uuid"
									:disabled="$store.getters.permissionsStr('选择账号')"
									>选择账号</el-button
								>
								<el-button @click="delTableData(scope.row)" class="redColor" size="medium" type="text" v-show="scope.row.uuid" :disabled="$store.getters.permissionsStr('删除')"
									>删除</el-button
								>
								<!-- v-show="!$store.getters.permissionsStr('删除')" -->
							</template>
						</el-table-column>
					</el-table>
					<el-pagination
						:current-page.sync="page"
						:page-size="page_size"
						:total="total"
						@current-change="handleCurrentChange"
						class="table-pagination"
						layout="total, prev, pager, next , jumper"
					></el-pagination>
				</div>
			</div>
		</el-row>
		<el-row>
			<!--    新增对话框-->
			<el-dialog :close-on-click-modal="false" :title="addMenuTitle" :visible.sync="dialogVisible" v-loading="diaLoading" center width="70%" :show-close="false">
				<div class="dialog_content">
					<div class="top_input">
						<span><strong>*</strong>训练模版名称</span><el-input placeholder="请输入训练模版名称" maxlength="40" style="width: 91.5%" v-model="templateName"></el-input>
					</div>
					<div class="content_bot" v-for="(item, index) in templateList" :key="index">
						<div class="c_bot_tit">
							<span>{{ index + 1 }}、训练部位</span>
							<el-select v-model="item.position_uuid" placeholder="请选择训练部位" @change="getActionList(item.position_uuid, index)">
								<el-option v-for="(ia, ix) in positionList" :key="ix" :label="ia.name" :value="ia.uuid"></el-option>
							</el-select>
							<el-button @click="addAction(index)" class="new_btn" round><i class="el-icon-plus add_icon"></i>增加动作</el-button>
						</div>
						<ul>
							<li v-for="(itm, idx) in item.action" :key="idx">
								<span>训练动作</span>
								<el-select v-model="itm.action_uuid" placeholder="请选择训练动作">
									<el-option v-for="(va, vx) in item.actionList" :key="vx" :label="va.name" :value="va.uuid"></el-option>
								</el-select>
								<span class="li_name">组数</span><el-input v-model="itm.gnum"><span slot="suffix" class="el-input__icon">组</span></el-input> <span class="li_name">次数</span
								><el-input v-model="itm.tnum"><span slot="suffix" class="el-input__icon">次</span></el-input> <span class="li_name">时长</span
								><el-input v-model="itm.timelen"><span slot="suffix" class="el-input__icon">s</span></el-input> <span class="li_name">组间间歇</span
								><el-input v-model="itm.ggap"></el-input> <span class="li_name">负荷</span><el-input v-model="itm.loads"></el-input>
								<i class="el-icon-error del_icon" @click="delAction(index, idx)"></i>
							</li>
						</ul>
					</div>
					<el-button @click="addPart" class="new_btn_part" round><i class="el-icon-plus add_icon"></i>增加部位</el-button>
				</div>
				<div class="form-bottom-button clearfix">
					<el-button type="primary" v-no-more-click class="save_btn" round @click="submitAdd">保 存</el-button>
					<el-button class="cancel_btn" round @click="resetForm">取 消</el-button>
				</div>
			</el-dialog>
			<!-- 选择账号 -->
			<el-dialog :close-on-click-modal="false" :visible.sync="dialogTextIndex" :before-close="text_cancel" title="选择账号" width="50%" center>
				<div class="ModifyDialog">
					<div class="textDialog_left">
						<div class="textDialog_left_input">
							<!-- <el-select v-model="selectValue" clearable @change="getAccountList">
                <el-option v-for="(item, index) in roleList" :key="index" :label="item.name" :value="item.uuid"></el-option>
              </el-select> -->
							<el-input
								@keyup.enter.native="getAccountList"
								class="left-child-input left-child-input-content"
								clearable
								placeholder="请输入账号名称"
								suffix-icon="iconfont iconsousuo"
								v-model="inputValue"
							>
							</el-input>
						</div>
						<h2>账号列表</h2>
						<div class="tree_demo">
							<el-tree
								:data="accountList"
								ref="textTreeNode"
								show-checkbox
								node-key="uuid"
								:props="defaultProps"
								:check-strictly="false"
								:check-on-click-node="false"
								:filter-node-method="filterNode"
								@check-change="checkChange"
							>
							</el-tree>
						</div>
					</div>
					<div class="textDialog_right">
						<p>已选账号 ( {{ selectTreeData.length }}/{{ accountList[0].norms.length }} )<span @click="clickTreeAll">清空</span></p>
						<div class="tree_demo">
							<el-tree :data="selectTreeData" node-key="uuid" :props="defaultProps">
								<span class="custom-tree-node" slot-scope="{ node, data }">
									<span>{{ node.label }}</span>
									<span>
										<i class="iconfont iconshanchu-moren" @click="textTreeCancel(node, data)"></i>
									</span>
								</span>
							</el-tree>
						</div>
					</div>
				</div>
				<!-- <p class="bz_content">*注：一个指标同属于多个分类，指标只呈现在一个分类中</p> -->
				<div class="form-bottom-button">
					<el-button @click="text_save" type="primary" v-no-more-click class="save_btn" round>保存</el-button>
					<el-button @click="text_cancel" class="cancel_btn" round>取消</el-button>
				</div>
			</el-dialog>
		</el-row>
	</div>
</template>

<script>
import accountVue from '../system/account.vue'
export default {
	data() {
		return {
			total: 0,
			page: 1,
			page_size: 10,
			keyword: '',
			addMenuTitle: '',
			handleType: true,
			pictLoading: false,
			dialogVisible: false,
			dialogTextIndex: false,
			diaLoading: false,
			tableData: [{ uuid: 0 }], // 表格数据
			selectCheckData: [], // 表格选中数据列表
			formAdd: {},
			positionList: [], // 训练部位列表
			// actionList: [], //训练动作列表
			templateUuid: '',
			templateName: '',
			templateList: [
				{
					value: '',
					actionList: [],
					action: [
						{
							action_uuid: '',
							gnum: '',
							tnum: '',
							timelen: '',
							ggap: '',
							loads: '',
						},
					],
				},
			],
			accountUuid: '',
			inputValue: '',
			selectValue: '',
			selectNum: 0,
			textAllNum: 0,
			textTreeData: [],
			defaultProps: {
				children: 'norms',
				label: 'name',
			},
			selectTreeData: [],
			roleList: [],
			accountList: [
				{
					name: '账号（姓名）',
					uuid: '0',
					norms: [],
				},
			],
		}
	},
	watch: {
		inputValue(val) {
			this.$refs.textTreeNode.filter(val)
		},
	},
	mounted() {
		this.create(1)
		this.$axios
			.post(
				'p/role/lists',
				this.$qs({
					page: 1,
					page_size: 999999,
				})
			)
			.then(res => {
				this.roleList = res.data.data.lists
			})
		this.getAccountList()
	},
	methods: {
		//列表
		create() {
			this.pictLoading = true
			this.$axios
				.post(
					'p/template/lists',
					this.$qs({
						page: this.page,
						page_size: this.page_size,
						keyword: this.keyword,
					})
				)
				.then(res => {
					this.pictLoading = false
					this.tableData = res.data.data.rows
					this.total = res.data.data.total
				})
		},
		search() {
			this.page = 1
			this.create()
		},
		resetForm() {
			this.dialogVisible = false
			this.templateName = ''
			this.templateList = [
				{
					position_uuid: '',
					actionList: [],
					action: [
						{
							action_uuid: '',
							gnum: '',
							tnum: '',
							timelen: '',
							ggap: '',
							loads: '',
						},
					],
				},
			]
		},
		selsChange(rows) {
			this.selectCheckData = rows
		},
		handleCurrentChange(val) {
			this.page = val
			this.create()
		},
		// 新增模板--增加动作
		addAction(index) {
			let obj = {
				action_uuid: '',
				gnum: '',
				tnum: '',
				timelen: '',
				ggap: '',
				loads: '',
			}
			this.templateList[index].action.push(obj)
		},
		// 新增模板--删除动作
		delAction(index, idx) {
			this.templateList[index].action.splice(idx, 1)
			if (!this.templateList[index].action.length) {
				this.templateList.splice(index, 1)
			}
		},
		// 新增模板--增加部位
		addPart() {
			this.templateList.push({
				position_uuid: '',
				actionList: [],
				action: [
					{
						action_uuid: '',
						gnum: '',
						tnum: '',
						timelen: '',
						ggap: '',
						loads: '',
					},
				],
			})
		},
		getActionList(uuid, index, type) {
			if (!type) {
				this.templateList[index].action.forEach((itm, idx) => {
					itm.action_uuid = ''
				})
			}
			this.$axios
				.post(
					'p/action/all',
					this.$qs({
						uuid: uuid,
						type: 'position',
					})
				)
				.then(res => {
					this.templateList[index].actionList = res.data.data
					this.$forceUpdate()
				})
		},
		getIndicator(rows) {
			this.dialogTextIndex = true
			this.accountUuid = rows.uuid
			this.getAccountList(rows.uuid)
		},
		// 新建模板保存
		submitAdd() {
			let obj = {
				name: this.templateName,
			}
			if (!this.handleType) {
				obj.uuid = this.templateUuid
			}
			let arr = []
			if (this.templateList.length) {
				this.templateList.forEach((item, index) => {
					item.action.forEach((itm, idx) => {
						itm.tnum = Number(itm.tnum)
						arr.push(itm)
					})
				})
				obj.detail = JSON.stringify(arr)
			}
			let url = this.handleType ? '/p/template/add' : '/p/template/update'
			this.$axios
				.post(url, this.$qs(obj))
				.then(res => {
					if (res.data.code == 0) {
						this.$message({
							message: res.data.message,
							type: 'success',
						})
						this.create()
						this.resetForm()
					} else {
						this.$message({
							message: res.data.message,
							type: 'error',
						})
					}
				})
				.catch(error => {
					this.$message({
						message: error,
						type: 'error',
					})
				})
		},
		// 删除列表
		delTableData(data) {
			//删除数据
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: '',
				center: true,
			}).then(() => {
				this.$axios
					.post(
						'/p/template/del',
						this.$qs({
							uuids: data.uuid,
						})
					)
					.then(res => {
						if (res.data.code == 0) {
							this.$message({
								message: res.data.message,
								type: 'success',
							})
							this.create()
						} else {
							this.$message({
								message: res.data.message,
								type: 'error',
							})
						}
					})
			})
		},
		//新增表单弹窗
		postAdd() {
			this.handleType = true
			this.dialogVisible = true
			this.addMenuTitle = '新增训练模板'
			this.$axios.post('p/position/all').then(res => {
				this.positionList = res.data.data
			})
		},
		postEdit(data) {
			this.handleType = false
			this.dialogVisible = true
			this.diaLoading = true
			this.templateUuid = data.uuid
			this.addMenuTitle = '编辑训练模板'
			this.$axios
				.post(
					'p/position/all',
					this.$qs({
						type: 'template',
						uuid: data.uuid,
					})
				)
				.then(res => {
					this.positionList = res.data.data
				})
			this.$axios
				.post(
					'p/template/read',
					this.$qs({
						uuid: data.uuid,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.templateName = res.data.data.name
						this.templateList = res.data.data.detail
						res.data.data.detail.forEach((item, index) => {
							this.getActionList(item.position_uuid, index, true)
						})
						this.$forceUpdate()
						setTimeout(() => {
							this.diaLoading = false
						}, 500)
					} else {
						this.$message({
							message: res.data.message,
							type: 'error',
						})
					}
				})
				.catch(error => {
					this.$message({
						message: error,
						type: 'error',
					})
				})
		},
		ChangeState(rows) {
			this.$axios
				.post(
					'/p/template/able',
					this.$qs({
						uuid: rows.uuid,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.$message({
							message: res.data.message,
							type: 'success',
						})
						this.create()
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
		},
		someDels() {
			if (this.selectCheckData.length == 0) {
				this.$message({
					message: '请选择要删除的数据',
					type: 'error',
				})
				return
			}
			//删除数据
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: '',
				center: true,
			}).then(() => {
				let params = []
				for (let i = 0; i < this.selectCheckData.length; i++) {
					params[i] = this.selectCheckData[i].uuid
				}
				let ids = params.join(',')
				this.$axios
					.post(
						'/p/template/del',
						this.$qs({
							uuids: ids,
						})
					)
					.then(res => {
						if (res.data.code == 0) {
							this.$message({
								message: res.data.message,
								type: 'success',
							})
							this.create()
						} else {
							this.$message({
								message: res.data.message,
								type: 'error',
							})
						}
					})
					.catch(error => {
						this.$message({
							message: error,
							type: 'error',
						})
					})
			})
		},
		// 获取账号列表
		getAccountList(uuid) {
			this.$axios
				.post(
					'p/template/getAccount',
					this.$qs({
						role_uuid: this.selectValue,
						keyword: this.inputValue,
						template_uuid: this.accountUuid,
					})
				)
				.then(res => {
					this.accountList[0].norms = res.data.data
					let type = false
					this.$nextTick(() => {
						res.data.data.forEach(item => {
							if (item.checked) {
								type = true
								this.$refs.textTreeNode.setChecked(item.uuid, true)
							}
						})
						if (!this.selectTreeData.length) {
							this.selectTreeData.forEach(item => {
								this.$refs.textTreeNode.setChecked(item.uuid, true)
							})
						}
						this.$forceUpdate()
					})
					setTimeout(() => {
						if (type && !this.selectTreeData.length) {
							res.data.data.forEach(item => {
								if (item.checked) {
									this.selectTreeData.push(item)
								}
							})
						}
					}, 100)
				})
		},
		// 选择账号左侧选中
		checkChange(rows, type) {
			if (rows.uuid == 0 && type) {
				this.selectTreeData = JSON.parse(JSON.stringify(this.accountList[0].norms))
			} else if (rows.uuid == 0 && !type) {
				// this.selectTreeData = []
			} else if (type) {
				if (this.selectTreeData.length == this.accountList[0].norms.length) return false
				let ins = false
				this.selectTreeData.forEach(item => {
					if (item.uuid == rows.uuid) ins = true
				})
				if (!ins) {
					this.selectTreeData.push(rows)
				}
			} else {
				if (!this.selectTreeData.length) return false
				// = this.selectTreeData.indexOf(rows)
				let idx = -1
				this.selectTreeData.forEach((item, index) => {
					if (item.uuid == rows.uuid) {
						idx = index
					}
				})
				this.selectTreeData.splice(idx, 1)
			}
		},
		// 选择账号保存
		text_save() {
			let arr = ''
			if (this.selectTreeData.length) {
				this.selectTreeData.forEach((item, index) => {
					if (index == 0) {
						arr = item.uuid
					} else {
						arr += ',' + item.uuid
					}
				})
			}
			this.$axios
				.post(
					'p/template/selectCount',
					this.$qs({
						uuid: this.accountUuid,
						user_uuids: arr,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.$message({
							message: res.data.message,
							type: 'success',
						})
						this.text_cancel()
					} else {
						this.$message({
							message: res.data.message,
							type: 'error',
						})
					}
				})
		},
		// 选择账号取消
		text_cancel() {
			this.dialogTextIndex = false
			this.selectTreeData.forEach(item => {
				this.$refs.textTreeNode.setChecked(item.uuid, false)
			})
			this.selectTreeData = []
			this.inputValue = ''
			this.selectValue = ''
		},
		// 选择账号右侧删除
		textTreeCancel(node, data) {
			this.$refs.textTreeNode.setChecked(data.uuid, false)
		},
		// 选择账号右侧清空
		clickTreeAll() {
			this.selectTreeData.forEach(item => {
				this.$refs.textTreeNode.setChecked(item.uuid, false)
			})
		},
		filterNode(value, data) {
			if (!value) return true
			return data.name.indexOf(value) !== -1
		},
	},
}
</script>

<style lang="scss" scoped>
.child-top {
	.input-from {
		float: left;
		min-width: 300px;
		margin-bottom: 0;
		.searchBtn {
			margin-top: 20px;
		}
		.el-select,
		.el-input {
			margin-top: 20px;
		}
		.el-input.el-input--suffix.left-child-input {
			float: left;
			width: 200px;
			.el-input__inner {
				border-radius: 0 4px 4px 0;
			}
		}
	}
	.operation-button {
		margin-bottom: 10px;
	}
}
.ModifyDoalog {
	margin-top: -20px;
	padding: 0 0 0 20px;
	& > p {
		font-size: 20px;
		color: #3b3b3b;
		text-align: center;
		margin-bottom: 20px;
	}
	.el-form {
		padding-right: 30px;
		.form_inRact {
			display: flex;
			margin-bottom: 20px;
			.el-form-item:first-child {
				margin-right: 5%;
			}
		}
	}
}
.ModifyDialog {
	display: flex;
	height: 400px;
	padding: 0 30px;
	justify-content: space-between;
	margin-bottom: 10px;

	.textDialog_left {
		width: 45%;
		border: 1px solid #e3e3e3;
		overflow: hidden;
		.textDialog_left_input {
			padding: 10px 20px;
			.el-select {
				width: 45%;
			}
			.el-input {
				// margin-left: 10%;
				width: 100%;
			}
		}
		h2 {
			height: 50px;
			line-height: 50px;
			background: #f5f5f5;
			border-radius: 2px 2px 0px 0px;
			border: 1px solid #e3e3e3;
			border-left: none;
			border-right: none;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			color: #333;
			margin-top: 5px;
		}
		.tree_demo {
			height: 280px;
			overflow-y: auto;
			padding: 10px 0;
			box-sizing: border-box;
			.el-tree {
				font-size: 16px;
				/deep/ .el-tree-node {
					margin: 5px 0;
					.el-checkbox {
						margin-right: 10px;
						.is-checked {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
						.is-indeterminate {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
					}
					.el-tree-node__label {
						color: #333;
					}
				}
			}
		}
	}
	.textDialog_right {
		width: 45%;
		border: 1px solid #e3e3e3;
		p {
			height: 50px;
			line-height: 50px;
			background: #f5f5f5;
			border-radius: 2px 2px 0px 0px;
			border-bottom: 1px solid #e3e3e3;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			color: #333;
			position: relative;
			span {
				position: absolute;
				right: 20px;
				font-size: 14px;
				font-family: MicrosoftYaHei;
				color: #999999;
				&:hover {
					cursor: pointer;
				}
			}
		}
		.tree_demo {
			height: 345px;
			overflow-y: auto;
			padding: 20px;
			box-sizing: border-box;
			.el-tree {
				font-size: 16px;
				/deep/ .el-tree-node {
					margin: 5px 0;
					.el-checkbox {
						margin-right: 10px;
						.is-checked {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
						.is-indeterminate {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
					}
					.el-tree-node__label {
						color: #333;
					}
				}

				.custom-tree-node {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 14px;
					padding-right: 8px;
					i {
						&:hover {
							color: #f00;
						}
					}
				}
			}
		}
	}
}
.table-pagination {
	text-align: right;
	margin-top: 10px;
	/deep/ span {
		float: left;
	}
}
.newTable {
	height: 400px;
	overflow: auto;
	.el-table {
		width: 40%;
	}
}

/deep/ input::-webkit-input-placeholder {
	color: #999;
}
/deep/ input::-moz-input-placeholder {
	color: #999;
}
/deep/ input::-ms-input-placeholder {
	color: #999;
}

.iconshanchu-moren {
	&:hover {
		color: #f00;
		cursor: pointer;
	}
}

.el-pagination {
	background: #fff;
	padding: 10px;
	margin-top: 0;
}
.child-top .operation-button .del_btn:hover {
	background: #eb1e44;
	color: #ffffff;
}
.dialog_content {
	padding: 0 40px 10px;
	max-height: 500px;
	overflow-y: auto;
	.top_input {
		margin-bottom: 30px;
		span {
			strong {
				color: rgba(255, 29, 29, 1);
				margin-right: 5px;
			}
		}
	}
	.content_bot {
		border: 1px solid #dadada;
		padding: 33px;
		border-radius: 5px;
		margin-bottom: 10px;
		.c_bot_tit {
			margin-bottom: 30px;
			margin-left: -22px;
			span {
				margin-right: 10px;
			}
			.el-button {
				float: right;
				background: #1a9f16;
				font-family: PingFangSC-Regular, PingFang SC;
				color: #fff;
				font-size: 15px;
				padding-left: 15px;
				padding-right: 15px;
				&:hover {
					background: #31b82d;
				}
				.add_icon {
					margin-right: 10px;
					font-weight: 600;
					font-size: 17px;
				}
			}
		}
		ul {
			li {
				margin-bottom: 20px;
				.li_name {
					// margin-left: 30px;
					margin-left: 20px;
				}
				.el-input {
					width: 80px;
					/deep/ .el-input__suffix-inner {
						color: #424242;
					}
				}
				.el-select {
					margin-left: 10px;
					margin-right: 20px;
				}
				.del_icon {
					font-size: 22px;
					margin-left: 20px;
					color: #ed4a68;
					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}
	.new_btn_part {
		margin-top: 10px;
		font-family: PingFangSC-Regular, PingFang SC;
		color: #e09a10;
		font-size: 15px;
		padding-left: 15px;
		padding-right: 15px;
		border-color: #e09a10;
		&:hover {
			background: #fff;
			color: #f1c127;
			border-color: #f1c127;
		}
		.add_icon {
			margin-right: 10px;
			font-weight: 600;
			font-size: 17px;
		}
	}
}
.content_bot {
	.el-select {
		width: 150px;
	}
}
</style>
